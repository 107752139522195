import { Controller } from '@hotwired/stimulus'
import { type HTMLEvent } from '../types/html_event'

// Connects to data-controller="column-sort"
export default class extends Controller {
  connect (): void {
    document.addEventListener('turbo:submit-end', ({ detail: { success } }: any): void => {
      if (success === true) {
        const filterInput = document.querySelector<HTMLInputElement>('input[name="filter"]')

        if (filterInput === null) return

        const searchValue = filterInput.value

        const newUrl = new URL(window.location.href)

        if (searchValue.trim() === '') {
          newUrl.searchParams.delete('filter')
        } else {
          newUrl.searchParams.set('filter', searchValue)
        }

        // Update the URL without reloading the page
        history.pushState({}, '', newUrl)
      }
    })
  }

  sort (event: HTMLEvent): void {
    const { currentTarget } = event
    const { sort } = currentTarget.dataset

    const currentUrl = new URL(window.location.href)
    const params = currentUrl.searchParams

    const filterInput = document.querySelector<HTMLInputElement>('input[name="filter"]')

    if (filterInput !== null) {
      const searchValue = filterInput.value
      if (searchValue.trim() === '') {
        currentUrl.searchParams.delete('filter')
      } else {
        currentUrl.searchParams.set('filter', searchValue)
      }
    }

    const existingSort = params.get('sort')
    const existingSortReverse = params.get('sort_reverse')
    if (existingSort === sort && existingSortReverse === null) {
      params.set('sort_reverse', 'true')
    } else {
      params.delete('sort_reverse')
    }
    params.set('sort', sort ?? '')
    const updatedUrl = currentUrl.pathname + '?' + params.toString()

    window.location.href = updatedUrl
  }
}
