import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table-resizer"
export default class extends Controller {
  static targets = ['table']

  declare readonly tableTarget: HTMLTableElement

  connect (): void {
    this.makeTableResizable()
  }

  makeTableResizable (): void {
    const columns = this.tableTarget.querySelectorAll('th')
    columns.forEach((column) => {
      const resizer = document.createElement('div')
      resizer.classList.add('resizableTable-resizer')
      resizer.style.height = `${this.tableTarget.offsetHeight}px`
      column.appendChild(resizer)
      this.makeColumnResizable(column, resizer)
    })
  }

  makeColumnResizable (column: HTMLElement, resizer: HTMLElement): void {
    function mouseDownHandler (event: MouseEvent): void {
      x = event.clientX

      const columnStyles = window.getComputedStyle(column)

      originalWidth = parseInt(columnStyles.width)

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)

      resizer.classList.add('resizableTable-resizing')
      column.classList.add('pointerEvents-none')
    }

    function mouseMoveHandler (e: MouseEvent): void {
      const dx = e.clientX - x
      column.style.width = `${originalWidth + dx}px`
    }

    function mouseUpHandler (): void {
      resizer.classList.remove('resizableTable-resizing')
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
      column.classList.remove('pointerEvents-none')
    }

    let x = 0
    let originalWidth = 0

    resizer.addEventListener('mousedown', mouseDownHandler)
  }
}
